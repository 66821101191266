<template>
	<div class="app-container">		
		<el-button type="primary" :loading="buttonloading" class="mt-3 mb-3 float-right" v-b-modal.addRow v-if="permissionList.includes(permission.add)" icon="el-icon-plus">{{$t('button.add')}}</el-button>
		<el-table :data="tableData" v-loading="loading" style="width: 100%;" @expand-change="expandChange" @row-click="expandRow" class="mt-3" ref="tableTest" border fit highlight-current-row>
            <el-table-column v-for="title in ajaxTitles" :prop="title.prop" :label="title.label" :key="title.prop" :min-width="title.width">
                <template slot="header">
                    <p class="search-label">{{title.label}}</p>
                    <b-form-input :placeholder="title.label" @keyup.enter.native="initial" v-model="searchData[title.prop]" @change="saveData()"></b-form-input>
                </template>
            </el-table-column>
            <el-table-column type="expand">
                <template slot-scope="scope">
                    <el-button type="primary" :loading="buttonloading" @click="getEditRow(scope.row.id)" v-if="permissionList.includes(permission.edit)" icon="el-icon-edit">{{$t('button.edit')}}</el-button>
                    <el-button type="danger" :loading="buttonloading" @click="deleteRow(scope.row.id)" v-if="permissionList.includes(permission.delete)" icon="el-icon-delete">{{$t('button.delete')}}</el-button>
                </template>
            </el-table-column>
        </el-table>

		<pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="pagination"/>
		
		<el-tooltip placement="top" :content="$t('general.back_to_top')">
			<backtotop :visibility-height="300" :back-position="50" transition-name="fade" />
		</el-tooltip>

        <b-modal id="addRow" :title="$t('menu.tools_language_add')" @hide="clearDataList()" hide-footer>
			<el-form @keyup.enter.native="addRow()" >
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_name')}}</template>
					<b-form-input v-model="dataList.name"></b-form-input>
				</b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_code')}}</template>
					<b-form-input v-model="dataList.code"></b-form-input>
				</b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_directory')}}</template>
					<b-form-input v-model="dataList.directory"></b-form-input>
				</b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_mobile_code')}}</template>
					<b-form-input v-model="dataList.mobile_code"></b-form-input>
				</b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_short_name')}}</template>
					<b-form-input v-model="dataList.short_name"></b-form-input>
				</b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_sort')}}</template>
					<el-input-number v-model="dataList.sort" :min="0" class="w-100" :precision="0"></el-input-number>
				</b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_admin')}}</template>
                    <el-switch v-model="dataList.is_admin" active-value="1" inactive-value="0"> </el-switch>
				</b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_status')}}</template>
                    <el-switch v-model="dataList.status" active-value="1" inactive-value="0"> </el-switch>
				</b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_security')}}</template>
					<b-form-input v-model="dataList.security" type="password" :placeholder="$t('msg.msg_security_password')"></b-form-input>
				</b-form-group>
			</el-form>

			<el-footer align="center" class="element-footer">
				<el-button size="medium" @click="$bvModal.hide('addRow')">{{$t('button.cancel')}}</el-button>
				<el-button size="medium" type="primary" @click="addRow()" :loading="buttonloading">{{$t('button.submit')}}</el-button>
			</el-footer>
        </b-modal>

		<b-modal id="editRow" :title="$t('menu.tools_language_edit')" @hide="clearDataList()" hide-footer>
			<el-form @keyup.enter.native="editRow()" >
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_name')}}</template>
					<b-form-input v-model="dataList.name"></b-form-input>
				</b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_code')}}</template>
					<b-form-input v-model="dataList.code"></b-form-input>
				</b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_directory')}}</template>
					<b-form-input v-model="dataList.directory"></b-form-input>
				</b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_mobile_code')}}</template>
					<b-form-input v-model="dataList.mobile_code"></b-form-input>
				</b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_short_name')}}</template>
					<b-form-input v-model="dataList.short_name"></b-form-input>
				</b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_sort')}}</template>
					<el-input-number v-model="dataList.sort" :min="0" class="w-100" :precision="0"></el-input-number>
				</b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_admin')}}</template>
                    <el-switch v-model="dataList.is_admin" active-value="1" inactive-value="0"> </el-switch>
				</b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_status')}}</template>
                    <el-switch v-model="dataList.status" active-value="1" inactive-value="0"> </el-switch>
				</b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_security')}}</template>
					<b-form-input v-model="dataList.security" type="password" :placeholder="$t('msg.msg_security_password')"></b-form-input>
				</b-form-group>
			</el-form>

			<el-footer align="center" class="element-footer">
				<el-button size="medium" @click="$bvModal.hide('editRow')">{{$t('button.cancel')}}</el-button>
				<el-button size="medium" type="primary" @click="editRow()" :loading="buttonloading">{{$t('button.submit')}}</el-button>
			</el-footer>
        </b-modal>
	</div>

</template>

<script>
import {getLocalStorage} from '@/system/store/localstorage';
import {postMethod} from '@/system/model/post/post';
import {getDAES, getEAES} from '@/system/library/security';
import pagination from '@/components/pagination';
import backtotop from '@/components/backtotop';

let searchForm = {
	pagination: 1,
	limit: 10,
	id: '',
	name:'',
	code:'',
	directory:'',
	mobile_code:'',
	short_name:'',
	sort:'',
	is_admin:'',
	status:''
}

export default{
	components: { pagination, backtotop },
	inject:['preloader'],
	data(){
		return {
			loading: true,
			buttonloading: false,
			tableData: [],
			total: 0,
			errors: [],
			submitForm: {
				id:'',
				security:'',
			},
			postData: {
				data: '',
				language: '',
			},
			searchData:Object.assign({}, searchForm),
			listQuery: {
				page: 1,
				limit: 10
			},
			ajaxTitles:[{
                prop: "id",
                label: this.$t('admin_general.table_id'),
                width:'50'
            },{
                prop: "name",
                label: this.$t('admin_general.table_name'),
                width:'100'
            },{
                prop: "code",
                label: this.$t('admin_general.table_code'),
                width:'50'
			},{
                prop: "directory",
                label: this.$t('admin_general.table_directory'),
                width:'100'
            },{
                prop: "mobile_code",
                label: this.$t('admin_general.table_mobile_code'),
                width:'50'
			},{
                prop: "short_name",
                label: this.$t('admin_general.table_short_name'),
                width:'50'
			},{
                prop: "is_admin",
                label: this.$t('admin_general.table_admin'),
                width:'60'
			},{
                prop: "sort",
                label: this.$t('admin_general.table_sort'),
                width:'60'
			},{
                prop: "status",
                label: this.$t('admin_general.table_status'),
                width:'60'
			}],
			coinList: [],
			dataList:{
				name:'',
				code:'',
				directory:'',
				mobile_code:'',
				short_name:'',
				sort:10,
				is_admin:'0',
				status:'',
				security:''
			},
			permission:{
                ajaxTable:'3JUOLG',
                add:'52TKQ5',
                DBadd:'F41WVV',
                edit:'JQK7Q3',
                DBedit:'MNO67T',
                delete:'93D69H',
                DBdelete:'E9ZLRA'
            },
            permissionList:[],
		}
	}, methods: {
		initial(){
			if(this.permissionList.includes(this.permission.ajaxTable)){
				this.loading = true;
				this.buttonloading = true;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.searchData));
				var self = this;
				var result = postMethod('tools/language/ajaxTable',this.postData);
				result.then(function(value){
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						self.tableData = data.datatable.data;
						self.total = parseInt(data.datatable.total_number);
						self.listQuery.page = parseInt(data.datatable.current_pagination);
						self.listQuery.limit = parseInt(data.datatable.limit);
						self.buttonloading = false;
						self.loading = false;
					}
				});
			}
		},clearDataList(){
			this.dataList.name = '';
			this.dataList.code = '';
			this.dataList.directory = '';
			this.dataList.mobile_code = '';
			this.dataList.short_name = '';
			this.dataList.sort = 10;
			this.dataList.is_admin = '0';
			this.dataList.security = '';
		},addRow(){
			if(this.permissionList.includes(this.permission.DBadd)){
				this.preloader(true);
				this.buttonloading = true;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.dataList));
				var text = '';
				var self = this;
				var result = postMethod('tools/language/DBadd',this.postData);

				result.then(function(value){
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						self.$message({
							message: data.returnMsg,
							type: 'success'
						});
						
						self.$bvModal.hide('addRow');
						self.initial();
					}else{					
						data.returnMsg.forEach(function (value){
							text+= value+"<br/>";
						});
						
						self.$method.popupErrorMessage(self,text);
					}
					self.buttonloading = false;
					self.preloader(false);
				});
			}
		},getEditRow(id){
			if(this.permissionList.includes(this.permission.edit)){
				this.buttonloading = true;
				this.submitForm.id = id;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.submitForm));
				var self = this;
				var result = postMethod('tools/language/edit',this.postData);
				result.then(function(value){
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						self.dataList = data.thisDetail;
						self.$bvModal.show('editRow');
					}
					self.buttonloading = false;
				});
			}
		},editRow(){
			if(this.permissionList.includes(this.permission.DBedit)){
				this.buttonloading = true;
				this.preloader(true);
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.dataList));
				var text = '';
				var self = this;
				var result = postMethod('tools/language/DBedit',this.postData);

				result.then(function(value){
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						self.$message({
							message: data.returnMsg,
							type: 'success'
						});
						
						self.$bvModal.hide('editRow');
						self.initial();
					}else{					
						data.returnMsg.forEach(function (value){
							text+= value+"<br/>";
						});
						
						self.$method.popupErrorMessage(self,text);
					}
					
					self.buttonloading = false;
					self.preloader(false);
				});
			}
		},deleteRow(id){
			if(this.permissionList.includes(this.permission.DBdelete)){
				this.buttonloading = true;
				var text = '';
				var self = this;
				this.$prompt(this.$t('msg.msg_delete'), this.$t('msg.prompt'), {
					confirmButtonText: this.$t('button.confirm'),
					cancelButtonText: this.$t('button.cancel'),
					inputPlaceholder: this.$t('msg.msg_security_password'),
					inputType: 'password'
				}).then(({ value }) => {
					this.submitForm.id = id;
					this.submitForm.security = value;
					this.postData.data = '';
					this.postData.data = getEAES(JSON.stringify(this.submitForm));
					var result = postMethod('tools/language/DBdelete',this.postData);
					result.then(function(value){
					var data = JSON.parse(getDAES(value.data));
						if(value.valid){
							self.$message({
							type: 'success',
							message: data.returnMsg
							});
							
							self.initial();
							self.buttonloading = false;
						}else{
							self.errors = data.returnMsg;
							
							self.errors.forEach(function (value){
							text+= value+"<br/>";
							});
							
							self.$method.popupErrorMessage(self, text);
							self.buttonloading = false;
						}
					
					});
				}).catch(() => {
					this.buttonloading = false;          
				});
			}
		},pagination(){
			this.searchData.pagination = this.listQuery.page;
			this.searchData.limit = this.listQuery.limit;
			this.initial();
		},expandRow(row){
            this.$refs.tableTest.toggleRowExpansion(row);
        },expandChange(row,expandedRows){
            if(expandedRows.length>1){
                this.$refs.tableTest.toggleRowExpansion(expandedRows[0]);
            }
        },saveData(){
			searchForm = this.searchData;
		}
	}, created: function(){
		var currentLang = (getLocalStorage('currentLang')) ? getLocalStorage('currentLang') : 'en';
		this.permissionList = JSON.parse(getLocalStorage('permissionList'));
		this.postData.language = currentLang;
		this.initial();
	}
}
</script>